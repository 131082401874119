// PrivateRoute.js
import React, { useEffect } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ element: Element, role, ...rest }) => {
  const { token, user } = useSelector((state) => state.auth);
  
  return (
    
        token && user && (user.role === role[0] || user.role === role[1]) ? <Element /> : (!user ? (<Navigate to="/login" replace />)  : (<Navigate to="/unauthorized" replace />))
  
  );
};

export default PrivateRoute;
import React from "react";

import { Row, Col } from "antd";

import { MdOutlineCheckCircle, MdOutlineErrorOutline } from "react-icons/md";

const PasswordError = () => {
  return (
    <Row className="container">
      <Col xs={22} sm={18} md={15} lg={12} xl={10} className="innerContainer">
        <div className="innerBox">
          <div className="logo">
            {/* <img src={logo} /> */}
          </div>
          <div className="checkCircle">
            <MdOutlineErrorOutline style={{ color: "red" }} />
          </div>
          <div className="changePassword">
            <h3>Password Update Failed</h3>
            <p>Link is invalid or had expired</p>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default PasswordError;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Col, Input, Modal, Pagination, message , Select } from "antd";

const { Option } = Select;


const InvoiceList = ({ data }) => {
   const location = useLocation();
   const [invoices, setInvoices] = useState([]);
   const [drivers, setDrivers] = useState([]);
   const [currentDriver, setCurrentDriver] = useState(null);
   const [selectedCompany, setSelectedCompany] = useState(
     location?.state?.company ? location.state.company : data ? data[0].id : 0
   );
   const [showCommentPopup, setShowCommentPopup] = useState(false);
   const [showDeletePopup, setShowDeletePopup] = useState(false);
   const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
   const [error, setError] = useState("");
   const [comment, setComment] = useState("");
   const [currentPage, setCurrentPage] = useState(1);
   const [currentInvoicePage, setCurrentInvoicePage] = useState(1);
   const [smallScreen, setSmallScreen] = useState(false);
   const [smallScreen1, setSmallScreen1] = useState(false);
  const pageSize = !smallScreen ? 4 : 4;

  const [currentInvoicePageData,setCurrentInvoicePageData] = useState([])

   const { token } = useSelector((state) => state.auth);
   const navigate = useNavigate();

  //  const invoiceStartIndex = (currentInvoicePage - 1) * pageSize;
  //  const invoiceEndIndex = Math.min(
  //    invoiceStartIndex + pageSize,
  //    invoices.length
  //  );
  //  const currentInvoicePageData = invoices.slice(
  //    invoiceStartIndex,
  //    invoiceEndIndex
  //  );

   const [currentPageData, setCurrentPageData] = useState([]);
   const [filteredData, setFilteredData] = useState([]);
   const [currentFilterValue, setCurrentFilterValue] = useState(1);

   const totalPages = Math.ceil(drivers.length / pageSize);
   const totalInvoicePages = Math.ceil(invoices.length / pageSize);

   const handleFilterChange = (value) => {
     setCurrentFilterValue(value);
   };

  const handlePageChange = (page) => {
     
     setCurrentPage(page);
   };

  const handleInvoicePageChange = (page) => {
     
     setCurrentInvoicePage(page);
   };

   const handleDeleteButtonClick = (invoiceId) => {
     setSelectedInvoiceId(invoiceId);
     setShowDeletePopup(true);
   };

   const handleInvoiceDelete = async () => {
     try {
       await axios.delete(
         `https://altablue.cloud/api/invoices/${selectedInvoiceId}`,
         {
           headers: {
             Authorization: `Bearer ${token}`,
           },
         }
       );
       message.success("Invoice deleted successfully!");
       setShowDeletePopup(false);
       fetchInvoices(currentDriver);
     } catch (error) {
       message.error("Error Deleting invoice!");
     }
   };

   useEffect(() => {

     const handleResize = () => {
       setSmallScreen(window.innerWidth <= 1168);
     };

     handleResize();
     window.addEventListener("resize", handleResize);
     return () => {
       window.removeEventListener("resize", handleResize);
     };
   }, []);

   useEffect(() => {
     const fetchDrivers = async () => {
       if (selectedCompany !== 0) {
         try {
           const response = await axios.get(
             `https://altablue.cloud/api/drivers/company/${selectedCompany}`,
             {
               headers: {
                 Authorization: `Bearer ${token}`,
               },
             }
           );
           setDrivers(response?.data?.data);
           setError("");
           setSmallScreen1(false);
         } catch (error) {
           setError("Drivers not found.");
           setDrivers([]);
         }
       }
     };
     fetchDrivers();
   }, [selectedCompany, token]);

  useEffect(() => {
    const sortedDrivers = [...invoices].sort((a, b) => {
      if (currentFilterValue === 1) {
        return a.id.localeCompare(b.id);
      } else if (currentFilterValue === 2) {
        return new Date(a.createdat) - new Date(b.createdat);
      } else if (currentFilterValue === 3) {
        return new Date(b.createdat) - new Date(a.createdat);
      }
      return 0;
    });

    const startIndex = (currentInvoicePage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, sortedDrivers.length);
    setCurrentInvoicePageData(sortedDrivers.slice(startIndex, endIndex));
  }, [
    currentFilterValue,
    invoices,
    currentPage,
    pageSize
  ]);

   const fetchInvoices = async (driver) => {
     try {
       const response = await axios.get(
         `https://altablue.cloud/api/invoices/all/${driver.id}`,
         {
           headers: {
             Authorization: `Bearer ${token}`,
           },
         }
       );
       setInvoices(response.data.data);
       setError("");
       setCurrentDriver(driver);
       setCurrentInvoicePage(1);
     } catch (error) {
       setError("Invoices not found.");
       setInvoices([]);
     }
   };

   const handleCommentButtonClick = (invoiceId) => {
     setSelectedInvoiceId(invoiceId);
     setShowCommentPopup(true);
   };

   const handleStatusChange = async (invoiceId, status) => {
     try {
       await axios.patch(
         `https://altablue.cloud/api/invoices/status/${invoiceId}`,
         { status },
         {
           headers: {
             Authorization: `Bearer ${token}`,
           },
         }
       );
       fetchInvoices(currentDriver);
     } catch (error) {
       setError("Error updating status:");
     }
   };

   const formatDate = (dateString) => {
     const options = { year: "numeric", month: "long", day: "numeric" };
     return new Date(dateString).toLocaleDateString(undefined, options);
   };

   const handleCommentSubmit = async () => {
     try {
       await axios.patch(
         `https://altablue.cloud/api/invoices/comments/${selectedInvoiceId}`,
         { comment },
         {
           headers: {
             Authorization: `Bearer ${token}`,
           },
         }
       );
       message.success("Comment added successfully!");
       setShowCommentPopup(false);
       setComment("");
       fetchInvoices(currentDriver);
     } catch (error) {
       message.error("Error adding comment:");
       setError("Error adding comment:");
     }
   };

   const viewInvoiceDetails = async (invoice) => {
     try {
       navigate("/dashboard/invoices", {
         state: {
           invoice: invoice,
           driver: currentDriver,
           company: selectedCompany,
         },
       });
     } catch (error) {
       console.error("Error fetching invoice details:", error);
     }
   };

  return (
    <div className="companyDetailsection">
      <Col className="invoicesList" xs={24} sm={23} md={23} lg={23} xl={23}>
        <div className="chooseCompany">
          <label htmlFor="companySelect">Choose Company</label>
          {/* <select
            id="companySelect"
            value={selectedCompany !== 0 ? selectedCompany : 0}
            onChange={(e) => setSelectedCompany(parseInt(e.target.value) || 0)}
          >
            <option key={1} value={0}>
              Select Company
            </option>
            {data &&
              data.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
          </select> */}

          <Select
            onChange={(value) => setSelectedCompany(value)}
            defaultValue={selectedCompany}
          >
            {data && data.map((company) => (
              <Option key={company.id} value={company.id}>
                {company.name}
              </Option>
            ))}
          </Select>
        </div>

        <div className="invoiceBottomPart">
          {!smallScreen ? (
            <>
              <div className="invoiceBottomPartOne">
                <h2>Manage Invoices</h2>
                <div className="manageInvoices manageInvoicesHeading">
                  <div>Email</div>
                  <div>User Name</div>
                  <div>Invoices</div>
                </div>
                <div>
                  {drivers &&
                    drivers
                      .slice(
                        (currentPage - 1) * pageSize,
                        currentPage * pageSize
                      )
                      .map((driver) => (
                        <div className="manageInvoicesItem" key={driver.id}>
                          <div>{driver.email}</div>
                          <div>{driver.fname + " " + driver.lname}</div>
                          <div>
                            <button
                              onClick={() => {
                                fetchInvoices(driver);
                              }}
                            >
                              Invoices
                            </button>
                          </div>
                        </div>
                      ))}
                  {drivers.length === 0 && <div>{error}</div>}
                </div>
                {currentPageData.length > 0 && (
                  <div className="paginationInvoices">
                    <div>
                      <span>
                        Showing {(currentPage - 1) * pageSize + 1} to{" "}
                        {Math.min(currentPage * pageSize, drivers?.length)} out
                        of {drivers?.length} entities
                      </span>
                    </div>
                    <Pagination
                      current={currentPage}
                      total={drivers?.length}
                      pageSize={pageSize}
                      onChange={handlePageChange}
                    />
                  </div>
                )}
              </div>

              <div className="invoiceBottomPartTwo">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h2>Invoices</h2>
                  <Select
                    defaultValue={1}
                    style={{
                      width: 120,
                      marginRight: "12px",
                    }}
                    onChange={handleFilterChange}
                    options={[
                      {
                        value: 1,
                        label: "Alphabetical Order",
                      },
                      {
                        value: 2,
                        label: "Oldest to Newest",
                      },
                      {
                        value: 3,
                        label: "Newest to Oldest",
                      },
                    ]}
                  />
                </div>

                <div className="styled-table2">
                  <div>
                    <div className="manageInvoices manageInvoicesHeading">
                      <div>Invoice</div>
                      <div>Status</div>
                    </div>
                  </div>
                  <div>
                    {currentInvoicePageData &&
                      currentInvoicePageData.map((invoice, index) => (
                        <div className="manageInvoicesItem" key={invoice.id}>
                          <div className="manageInvoiceCard">
                            <div>
                              <div
                                className={
                                  smallScreen
                                    ? "invoiceDetails"
                                    : "invoiceDetailsSmall"
                                }
                              >
                                <div key={invoice.invoice_number}>
                                  <div className="invoiceDetailsEntry">
                                    <div>{invoice.invoice_number}</div>
                                    <div>$ {invoice.totalamount}</div>
                                  </div>
                                  <div className="invoiceDetailsEntry">
                                    <div>{formatDate(invoice.createdat)}</div>
                                    <div className="invoiceStatus">
                                      {invoice.currentstatus}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="buttonsInvoice">
                            <div>
                              <button
                                onClick={() => viewInvoiceDetails(invoice)}
                                className="viewButton"
                              >
                                View
                              </button>

                              <Button
                                disabled={invoice.currentstatus == "Pending"}
                                className="deleteButton"
                                onClick={() =>
                                  handleDeleteButtonClick(invoice.id)
                                }
                              >
                                Delete
                              </Button>
                            </div>
                            <div className="selectButton">
                              {invoice.currentstatus == "Pending" ? (
                                <select
                                  className={
                                    invoice.currentstatus == "Pending"
                                      ? "editButton"
                                      : "deleteButton"
                                  }
                                  value={invoice.currentstatus}
                                  onChange={(e) =>
                                    handleStatusChange(
                                      invoice.id,
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="Pending">Pending</option>
                                  <option value="Void">Void</option>
                                  <option value="Completed">Complete</option>
                                </select>
                              ) : (
                                <Button className="editButton">
                                  {invoice.currentstatus}
                                </Button>
                              )}

                        {invoice.currentstatus == "Pending" && (
                             
                             <button
                               onClick={() =>
                                 handleCommentButtonClick(invoice.id)
                               }
                               className="commentButton"
                             >
                               Comment
                             </button>
                          
                         )}
                            </div>
                            <div>
                           



                             </div>
                          </div>
                        </div>
                      ))}
                    {invoices.length === 0 && (
                      <div>
                        <td colSpan="2">{error}</td>
                      </div>
                    )}
                  </div>
                </div>
                <div className="paginationInvoices">
                  <div>
                    {currentInvoicePageData.length > 0 && (
                      <span>
                        Showing {(currentInvoicePage - 1) * pageSize + 1} to{" "}
                        {Math.min(
                          currentInvoicePage * pageSize,
                          invoices.length
                        )}{" "}
                        out of {invoices.length} invoices
                      </span>
                    )}
                  </div>
                  <Pagination
                    current={currentInvoicePage}
                    total={invoices.length}
                    pageSize={pageSize}
                    onChange={handleInvoicePageChange}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
                {!smallScreen1 ? (
                  
                <>
                  <div className="invoiceBottomPartOneSmall">
                    <h2>Manage Invoices</h2>
                    <div className="manageInvoices manageInvoicesHeading">
                      <div>Username</div>
                      <div>Invoices</div>
                    </div>
                    <div>
                      {drivers &&
                        drivers
                          .slice(
                            (currentPage - 1) * pageSize,
                            currentPage * pageSize
                          )
                          .map((driver) => (
                            <div
                              className="manageInvoicesItem "
                              key={driver.id}
                            >
                              <div>{driver.email}</div>
                              <div>
                                <button
                                  onClick={() => {
                                    setSmallScreen1(true);
                                    fetchInvoices(driver);
                                  }}
                                >
                                  Invoices
                                </button>
                              </div>
                            </div>
                          ))}
                      {drivers.length === 0 && <div>{error}</div>}
                    </div>
                    {currentPageData.length > 0 && (
                      <div className="paginationInvoices">
                        <div>
                          <span>
                            Showing {(currentPage - 1) * pageSize + 1} to{" "}
                            {Math.min(currentPage * pageSize, drivers?.length)}{" "}
                            out of {drivers?.length} entities
                          </span>
                        </div>
                        <Pagination
                          current={currentPage}
                          total={drivers?.length}
                          pageSize={pageSize}
                          onChange={handlePageChange}
                        />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="invoiceBottomPartTwoSmall">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h2>Invoices</h2>
                      <Select
                        defaultValue={1}
                        style={{
                          width: 120,
                          marginRight: "12px",
                        }}
                        onChange={handleFilterChange}
                        options={[
                          {
                            value: 1,
                            label: "Alphabetical Order",
                          },
                          {
                            value: 2,
                            label: "Oldest to Newest",
                          },
                          {
                            value: 3,
                            label: "Newest to Oldest",
                          },
                        ]}
                      />
                    </div>

                    <div className="styled-table2">
                      <div>
                        <div className="manageInvoices manageInvoicesHeading">
                          <div>Invoice</div>
                          <div>Status</div>
                        </div>
                      </div>
                      <div>
                        {currentInvoicePageData &&
                          currentInvoicePageData.map((invoice, index) => (
                            <div
                              className="manageInvoicesItem"
                              key={invoice.id}
                            >
                              <div>
                                <div>
                                  <div className="invoiceDetails">
                                    <div key={invoice.id}>
                                      <div className="invoiceDetailsEntry">
                                        <div>{invoice.id}</div>
                                        <div>$ {invoice.totalamount}</div>
                                      </div>
                                      <div className="invoiceDetailsEntry">
                                        <div>
                                          {formatDate(invoice.createdat)}
                                        </div>
                                        <div className="invoiceStatus">
                                          {invoice.currentstatus}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="buttonsInvoice ">
                                <div>
                                  <button
                                    onClick={() => viewInvoiceDetails(index)}
                                    className="viewButton"
                                  >
                                    View
                                  </button>
                                </div>
                                <div>
                                  <select
                                    className={
                                      invoice.currentstatus == "Pending"
                                        ? "editButton"
                                        : "deleteButton"
                                    }
                                    value={invoice.currentstatus}
                                    onChange={(e) =>
                                      handleStatusChange(
                                        invoice.id,
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="Pending">Pending</option>
                                    <option value="Completed">Complete</option>
                                  </select>
                                </div>
                                <div>
                                  <button
                                    onClick={() =>
                                      handleCommentButtonClick(invoice.id)
                                    }
                                    className="commentButton"
                                  >
                                    Comment
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        {/* {invoices.length === 0 && (
                          <div>
                            <td colSpan="2">{error}</td>
                          </div>
                        )} */}
                      </div>
                    </div>
                    <div className="paginationInvoices">
                      <div>
                        {currentInvoicePageData.length > 0 && (
                          <span>
                            Showing {(currentInvoicePage - 1) * pageSize + 1} to{" "}
                            {Math.min(
                              currentInvoicePage * pageSize,
                              invoices.length
                            )}{" "}
                            out of {invoices.length} invoices
                          </span>
                        )}
                      </div>
                      <Pagination
                        current={currentInvoicePage}
                        total={invoices.length}
                        pageSize={pageSize}
                        onChange={handleInvoicePageChange}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        {showCommentPopup && (
          <Modal
            title="Add Comment"
            visible={showCommentPopup}
            onCancel={() => setShowCommentPopup(false)}
            footer={[
              <Button key="cancel" onClick={() => setShowCommentPopup(false)}>
                Cancel
              </Button>,
              <Button
                className="saveButton"
                key="submit"
                type="primary"
                onClick={handleCommentSubmit}
              >
                Submit
              </Button>,
            ]}
          >
            <Input.TextArea
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter your comment"
            />
          </Modal>
        )}

        {showDeletePopup && (
          <Modal
            title="Delete Invoice"
            visible={showDeletePopup}
            onCancel={() => setShowDeletePopup(false)}
            footer={[
              <Button
                className="saveButton"
                key="submit"
                type="primary"
                onClick={handleInvoiceDelete}
              >
                Yes
              </Button>,
              <Button key="no" onClick={() => setShowDeletePopup(false)}>
                No
              </Button>,
            ]}
          >
            <p>Are You sure you want to delete this invoice ?</p>
            <p>
              This action will permanently delete this invoice and can not be
              undone.
            </p>
          </Modal>
        )}
      </Col>
    </div>
  );
};

export default InvoiceList;

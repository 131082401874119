import React from 'react';
import { useDispatch } from 'react-redux';

import { Navigate } from 'react-router-dom';
import { logout } from './redux/authSlice';
import { useNavItem } from './nav/NavContext';

const Logout = () => {
  const dispatch = useDispatch();
  const { setSelectedItem, selectedItem } = useNavItem();

  // Dispatch the logout action and redirect to the home page
  const handleLogout = () => {
    dispatch(logout());
    return <Navigate to="/" />;
  };

  return (
    <div>
      <h2>Logging out...</h2>
      {handleLogout()}
    </div>
  );
};

export default Logout;

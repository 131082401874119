import { Col, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosCheckmarkCircle } from "react-icons/io";

const InvoiceDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [smallScreen, setSmallScreen] = useState(false);
  const { invoice, driver, company } = location.state;
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  useEffect(() => {

    const handleResize = () => {
      if (window.innerWidth <= 1168) {
        setSmallScreen(false);
      } else {
        setSmallScreen(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDocumentButtonClick = (documentData) => {
    setSelectedDocument(documentData);
    setShowDocumentModal(true);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div
      className={
        smallScreen ? "companyDetailsection" : "companyDetailsectionSmall"
      }
    >
      <Col className="invoicesList" xs={24} sm={23} md={23} lg={23} xl={23}>
        <div
          className={
            smallScreen
              ? "invoiceDetailsUpperSection"
              : "invoiceDetailsUpperSectionSmall"
          }
        >
          <div className={smallScreen ? "styled-table" : "styled-table-small"}>
            <div className="manageInvoices manageInvoicesHeadingOne">
              <div>Username</div>
              <div>Invoices</div>
            </div>
            <div
              key={driver.id}
              className="manageInvoicesItem manageInvoicesItemSelected"
            >
              <div>{driver.email}</div>
              <div>
                <button onClick={() => {navigate("/", {
        state: {  company },
      });}}>Invoices</button>
              </div>
            </div>
          </div>

          <div
            className={smallScreen ? "invoiceDetails" : "invoiceDetailsSmall"}
          >
            <div key={invoice.id}>
              <div className="invoiceDetailsEntry">
                <div>{invoice.invoice_number}</div>
                <div>$ {invoice.totalamount}</div>
              </div>
              <div className="invoiceDetailsEntry">
                <div>{formatDate(invoice.createdat)}</div>
                <div className="invoiceStatus">{invoice.currentstatus}</div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            smallScreen
              ? "invoiceDetailsBottomSection"
              : "invoiceDetailsBottomSectionSmall"
          }
        >
          <div
            className={
              smallScreen
                ? "invoiceDetailsBottomSectionLeft"
                : "invoiceDetailsBottomSectionLeftSmall"
            }
          >
            <div>
              <div className="invoiceLabel">Invoice Number</div>
              <div className="invoiceInput invoiceNumber">{invoice.invoice_number}</div>
            </div>
            <div>
              <div className="invoiceLabel">Load</div>
              <div className="invoiceInput">{invoice.load}</div>
            </div>
            <div>
              <div className="invoiceLabel">MC </div>
              <div className="invoiceInput">{invoice.mc}</div>
            </div>
            <div>
              <div className="invoiceLabel">Invoice Amount</div>
              <div className="invoiceInput">{invoice.invoiceamount}</div>
            </div>
            <div>
              <div className="invoiceLabel">Lumper Amount</div>
              <div className="invoiceInput"> {invoice.lumperamount}</div>
            </div>
            <div>
              <div className="invoiceLabel">Detention Amount</div>
              <div className="invoiceInput"> {invoice.detentionamount}</div>
            </div>
            <div>
              <div className="invoiceLabel">Advance Amount </div>
              <div className="invoiceInput"> {invoice.advanceamount}</div>
            </div>
            <div>
              <div className="invoiceLabel"></div>Total Amount
              <div className="invoiceInput">{invoice.totalamount}</div>
            </div>

          </div>

          <div
            className={
              smallScreen
                ? "invoiceDetailsBottomSectionRight"
                : "invoiceDetailsBottomSectionRightSmall"
            }
          >
            <div>
            <div>
              <div className="invoiceLabel"></div>Location
              <div className="invoiceInput">{invoice.location}</div>
            </div>
              <div className="landingButton">
                <div> Bill of Landing</div>
                <button
                  onClick={() =>
                    handleDocumentButtonClick(invoice.bill_of_landing)
                  }
                  className={
                    smallScreen ? "invoiceButton" : "invoiceButtonSmall"
                  }
                >
                  <IoIosCheckmarkCircle className="checkIcon" />
                  View Document
                </button>
              </div>
              <div className="landingButton">
                <div> Rate of Confirmation</div>
                <button
                  onClick={() =>
                    handleDocumentButtonClick(invoice.rate_of_confirmation)
                  }
                  className={
                    smallScreen ? "invoiceButton" : "invoiceButtonSmall"
                  }
                >
                  <IoIosCheckmarkCircle className="checkIcon" /> View Document
                </button>
              </div>
              <div className="landingButton">
                <div>Lumper Receipt</div>
                <button
                  onClick={() =>
                    handleDocumentButtonClick(invoice.lumper_reciept)
                  }
                  className={
                    smallScreen ? "invoiceButton" : "invoiceButtonSmall"
                  }
                >
                  <IoIosCheckmarkCircle className="checkIcon" /> View Document
                </button>
              </div>
            </div>
          </div>
        </div>

        <Modal
          visible={showDocumentModal}
          footer={null}
          onCancel={() => setShowDocumentModal(false)}
        >

{selectedDocument && selectedDocument.file_type?.endsWith('pdf')  ? (
      <iframe
      src={selectedDocument?.url}
      title="PDF Document"
      style={{width : "100%", height : "80vh"}}
      />
      ) : (
        <img
        src={selectedDocument?.url}
        alt="Document"
        style={{ width: "100%" }}
      />
       
      )}

        </Modal>
      </Col>
    </div>
  );
};

export default InvoiceDetail;

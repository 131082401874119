// PublicRoute.js
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PublicRoute = ({ element: Element, ...rest }) => {
  const { token, user } = useSelector((state) => state.auth);
  return (
    
        !(token && user) ? <Element /> : (<Navigate to="/dashboard" replace />)
  
  );
};

export default PublicRoute;
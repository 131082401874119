import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Col, Button, notification } from "antd";
import Logo from "../assets/alta-1 1.svg";

const ResetPasswordDriver = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const openNotification = (type, message) => {
    notification[type]({
      message: type === "success" ? "Success" : "Error",
      description: message,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      openNotification("error", "Passwords do not match.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.patch(
        `https://altablue.cloud/api/drivers/resetpassword/${token}`,
        {
          password,
        }
      );
      openNotification("success", response.data.message);
      navigate("/resetdone");
    } catch (error) {
      openNotification("error", error.response.data.error);
      navigate("/reseterror");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="loginpage">
      <Col xs={20} sm={16} md={12} lg={8} xl={8} className="loginScreen">
        <div className="logo center">
          <img
            style={
              { width: "140px" }
                
            }
            src={Logo}
          />
        </div>

        <div className="loginContent">
          <h3>Reset Password</h3>
          <form className="loginInputs" onSubmit={handleSubmit}>
            <label>New Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="inputLabel"
            />
            <label>Confirm Password:</label>
            <input
              className="inputLabel"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <Button
              style={{ marginTop: "20px" }}
              type="primary"
              htmlType="submit"
              className="loginButton"
              loading={loading}
            >
              Reset Password
            </Button>
          </form>
        </div>
      </Col>
    </div>
  );
};

export default ResetPasswordDriver;

import React, { useState, useEffect } from "react";
import { Col, Pagination, Modal, Button, Input, message, InputNumber, Select } from "antd";
import SearchBar from "../searchbar/SearchBar";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavItem } from "../nav/NavContext";

const CompanyList = ({ data, reload }) => {
  
   const [currentPage, setCurrentPage] = useState(1);
   const [smallScreen, setSmallScreen] = useState(false);
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [selectedCompany, setSelectedCompany] = useState(null);
   const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
   const [searchQuery, setSearchQuery] = useState("");
   const [isAddMode, setIsAddMode] = useState(false);
   const [filteredData, setFilteredData] = useState([]);
   const [error, setError] = useState(false);
   const [currentPageData, setCurrentPageData] = useState([]);
   const [newCompany, setNewCompany] = useState({
     name: "",
     dot: null,
     id: null,
     client_id: "",
     upcoming_invoice_no: 1,
   });
   const [editCompany, setEditCompany] = useState({ name: "", dot: null, client_id: "" });
   const { token } = useSelector((state) => state.auth);
   const [currentFilterValue, setCurrentFilterValue] = useState(1);
   const [pageSize, setPageSize] = useState(smallScreen ? 7 : 5);

   const { setSelectedItem, selectedItem } = useNavItem();

   const totalPages = Math.ceil(filteredData.length / pageSize);

   const handlePageChange = (page) => {
     setCurrentPage(page);
   };

   const handlePerPageChange = (value) => {
     if (value === 1) {
       setPageSize(5);
     } else if (value === 2) {
       setPageSize(10);
     } else if (value === 3) {
       setPageSize(15);
     }
     setCurrentPage(1); // Reset to first page when page size changes
   };

   const handleFilterChange = (value) => {
     let newData;
     if (value === 1) {
       newData = [...data].sort((a, b) => a.name.localeCompare(b.name));
     } else if (value === 3) {
       newData = [...data].sort(
         (a, b) => new Date(b.created_at) - new Date(a.created_at)
       );
     } else if (value === 2) {
       newData = [...data].sort(
         (a, b) => new Date(a.created_at) - new Date(b.created_at)
       );
     }
     setFilteredData(
       newData.filter((company) =>
         company?.name?.toLowerCase().includes(searchQuery.toLowerCase())
       )
     );
     setCurrentFilterValue(value);
     setCurrentPage(1); // Reset to first page when filter changes
   };

   const handleEditClick = (company) => {
     setSelectedCompany(company);
     setEditCompany({ name: company.name, dot: company.dot, client_id: company.client_id, upcoming_invoice_no : company.upcoming_invoice_no });
     setIsAddMode(false);
     setIsModalVisible(true);
   };

   const handleAddClick = () => {
     setSelectedCompany(null);
     setIsAddMode(true);
     setIsModalVisible(true);
   };

   const handleModalClose = () => {
     setIsModalVisible(false);
     setSelectedCompany(null);
     setNewCompany({ name: "", dot: null, id: null,client_id: "" });
     setEditCompany({ name: "", dot: null, client_id: "", upcoming_invoice_no : 1 });
   };

   const handleEditCompanyDetails = async () => {
     if (!editCompany?.name || !editCompany?.dot || !editCompany?.client_id) {
       message.error("Please enter all details!");
       return;
     }

     try {
       const response = await axios.put(
         `https://altablue.cloud/api/companies/${selectedCompany?.id}`,
         { name: editCompany.name, dot: editCompany.dot, client_id: editCompany.client_id },
         {
           headers: { Authorization: `Bearer ${token}` },
         }
       );
       if (response?.status === 200 && response?.data?.success) {
         message.success("Company updated successfully");
         reload();
       }
     } catch (error) {
       message.error("Company updating failed");
     }
   };

   const handleAddCompany = async () => {
     if (!newCompany?.name || !newCompany?.dot || !newCompany?.client_id) {
       message.error("Please enter company name, dot number, and client ID.");
       return;
     }

     try {
       const response = await axios.post(
         `https://altablue.cloud/api/companies/create`,
         { name: newCompany.name, dot: newCompany.dot, client_id: newCompany.client_id,upcoming_invoice_no: newCompany.upcoming_invoice_no },
         {
           headers: { Authorization: `Bearer ${token}` },
         }
       );
       if (response?.status === 200 && response?.data?.success) {
         message.success("Company added successfully");
         reload();
       }
     } catch (error) {
       message.error("Company addition failed");
     }
   };

   const handleSave = () => {
     if (isAddMode) {
       handleAddCompany();
     } else {
       handleEditCompanyDetails();
     }
     handleModalClose();
   };

   const handleDeleteCompany = (id) => {
     setSelectedCompany(id);
     setIsDeleteModalVisible(true);
   };

   const handleDeleteModalCancel = () => {
     setIsDeleteModalVisible(false);
     setSelectedCompany(null);
     setError(false);
   };

   const handleDeleteCompanySubmit = async () => {
     try {
       const response = await axios.delete(
         `https://altablue.cloud/api/companies/${selectedCompany.id}`,
         {
           headers: { Authorization: `Bearer ${token}` },
         }
       );

       message.success("Company deleted successfully!");
       setIsDeleteModalVisible(false);
       setError(false);
       reload();
     } catch (error) {
       message.error("Error deleting company");
       setError(true);
     }
   };

   const handleResize = () => {
     setSmallScreen(window.innerWidth > 1168);
   };

   useEffect(() => {
     handleResize();
     window.addEventListener("resize", handleResize);
     return () => {
       window.removeEventListener("resize", handleResize);
     };
   }, []);

   useEffect(() => {
    if(!data) {
      return;
    }
     const filtered = data.filter((company) =>
       company?.name?.toLowerCase().includes(searchQuery.toLowerCase())
     );

     const sortedFilteredData = filtered.sort((a, b) => {
       if (currentFilterValue === 1) {
         return a.name.localeCompare(b.name);
       } else if (currentFilterValue === 2) {
         return new Date(a.created_at) - new Date(b.created_at);
       } else if (currentFilterValue === 3) {
         return new Date(b.created_at) - new Date(a.created_at);
       }
       return 0;
     });

     setFilteredData(sortedFilteredData);
   }, [data, searchQuery, currentFilterValue]);

   useEffect(() => {
     const startIndex = (currentPage - 1) * pageSize;
     const endIndex = startIndex + pageSize;
     setCurrentPageData(filteredData.slice(startIndex, endIndex));
   }, [currentPage, pageSize, filteredData]);

  return (
    <div className="companyDetailsection">
      <Col
        className="companyDetailsProperty"
        xs={24}
        sm={23}
        md={23}
        lg={23}
        xl={23}
      >
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <div className="companyDetailsNavbar">
          <h3>Manage Company</h3>
          <div>
            <Button className="saveButton" onClick={handleAddClick}>
              + Add Company
            </Button>

            <Select
              disabled= {!data}
              defaultValue={1}
              style={{
                width: 120,
                marginRight: "12px",
              }}
              onChange={handleFilterChange}
              options={[
                {
                  value: 1,
                  label: "Alphabetical Order",
                },
                {
                  value: 2,
                  label: "Oldest to Newest",
                },
                {
                  value: 3,
                  label: "Newest to Oldest",
                },
              ]}
            />

            <Select
              defaultValue={1}
              style={{
                width: 120,
              }}
              onChange={handlePerPageChange}
              options={[
                {
                  value: 1,
                  label: "5 per page",
                },
                {
                  value: 2,
                  label: "10 per page",
                },
                {
                  value: 3,
                  label: "15 per page",
                },
              ]}
            />
          </div>
        </div>
        <div className="companyHeadingNav">
          <div className={!smallScreen ? "companyNavSmall" : "companyNavNew"}>
            <div>Company Name</div>
            <div>Dot Number</div>
            <div>Client ID</div>
            {smallScreen && <div>Modify</div>}
          </div>
          <div className="">
            {currentPageData.map((d, index) => (
              <div
                style={
                  !smallScreen && index % 2 !== 0
                    ? {
                        backgroundColor: "#e8ffff",
                      }
                    : {}
                }
                className="mainData"
                key={d.id}
              >
                <div
                  className={
                    !smallScreen
                      ? "companyHeadingBodySmall"
                      : "companyHeadingBodyNew"
                  }
                >
                  <div>{d.name}</div>
                  <div>{d.dot}</div>
                  <div>{d.client_id}</div>
                  {smallScreen && (
                    <div className="center editButtons">
                      <button
                        className="editButton"
                        onClick={() => {
                          handleEditClick(d);
                        }}
                      >
                        Edit
                      </button>
                      <Button
                        className="deleteButton"
                        onClick={() => {
                          setError(false);
                          handleDeleteCompany(d);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  )}
                </div>
                {!smallScreen && (
                  <div className="center editButtons shiftRightButton">
                    <button
                      className="editButton"
                      onClick={() => handleEditClick(d)}
                    >
                      Edit
                    </button>
                    <Button
                      className="deleteButton"
                      onClick={() => handleDeleteCompany(d)}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="pagination">
          <div>
            {currentPageData.length > 0 && (
              <span>
                Showing {(currentPage - 1) * pageSize + 1} to{" "}
                {Math.min(currentPage * pageSize, filteredData.length)} out of{" "}
                {filteredData.length} entities
              </span>
            )}
          </div>
          <div></div>
          <Pagination
            current={currentPage}
            total={filteredData.length}
            pageSize={pageSize}
            onChange={handlePageChange}
          />
        </div>
      </Col>
      <Modal
        title={isAddMode ? "Add Company" : "Edit Company"}
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <div className="popUpFooter">
            <Button
              className="saveButton"
              key="save"
              type="primary"
              onClick={handleSave}
            >
              Save
            </Button>
            <Button key="cancel" onClick={handleModalClose}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        {isAddMode ? (
          <div className="popupFooter">
            <div className="addModeInput">
              <div>
                Company Name
                <Input
                  placeholder="Company Name"
                  value={newCompany.name}
                  onChange={(e) =>
                    setNewCompany({ ...newCompany, name: e.target.value })
                  }
                />
              </div>

              <div>
                Dot Number
                <InputNumber
                  style={{
                    width: "100%",
                  }}
                  placeholder="Dot Number"
                  value={newCompany.dot}
                  onChange={(value) => {
                    setNewCompany({ ...newCompany, dot: value });
                  }}
                />
              </div>
            </div>
            <div>
              Client Id
              <Input
                style={{
                  width: "100%",
                }}
                placeholder="Client ID"
                value={newCompany.client_id}
                onChange={(e) =>
                  setNewCompany({ ...newCompany, client_id: e.target.value })
                }
              />
            </div>
            <div>
                Upcoming Invoice Number
                <InputNumber
                  style={{
                    width: "100%",
                  }}
                  placeholder="Upcoming Invoice Number"
                  value={newCompany.upcoming_invoice_no}
                  onChange={(value) => {
                    setNewCompany({ ...newCompany, upcoming_invoice_no: value });
                  }}
                />
              </div>
          </div>
        ) : (
          selectedCompany && (
            <div className="popupFooter">
              <div className="addModeInput">
                <div>
                  Company Name
                  <Input
                    placeholder="Company Name"
                    value={editCompany.name}
                    onChange={(e) =>
                      setEditCompany({ ...editCompany, name: e.target.value })
                    }
                  />
                </div>

                <div>
                  Dot Number
                  <InputNumber
                    placeholder="Dot Number"
                    value={editCompany.dot}
                    onChange={(value) =>
                      setEditCompany({ ...editCompany, dot: value })
                    }
                  />
                </div>
              </div>
              <div>
               
                Client Id
                <Input
                  placeholder="Client ID"
                  value={editCompany.client_id}
                  onChange={(e) =>
                    setEditCompany({ ...editCompany, client_id: e.target.value })
                  }
                />
            
              </div>
              <div>
                Upcoming Invoice Number
                <Input
                  placeholder="Upcoming Invoice Number"
                  value={editCompany.upcoming_invoice_no}
                  disabled
                />
                </div>
            </div>
          )
        )}
      </Modal>

      <Modal
        open={isDeleteModalVisible}
        onCancel={handleDeleteModalCancel}
        footer={
          !error
            ? [
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    className="saveButton"
                    key="submit"
                    type="primary"
                    style={{ marginRight: "24px" }}
                    onClick={handleDeleteCompanySubmit}
                  >
                    Yes
                  </Button>
                  <Button key="no" onClick={() => handleDeleteModalCancel()}>
                    No
                  </Button>
                </div>,
              ]
            : [
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  {error && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Button
                        className="saveButton"
                        key="submit"
                        type="primary"
                        onClick={() =>
                          setSelectedItem({
                            id: 4,
                            url: "https://altablue.cloud/api/companies/all",
                          })
                        }
                      >
                        Manage Invoices
                      </Button>

                      <Button
                        className="invoiceButton"
                        style={{ marginRight: "12px" }}
                        key="submit"
                        type="primary"
                        onClick={() =>
                          setSelectedItem({
                            id: 3,
                            url: "https://altablue.cloud/api/drivers/all",
                          })
                        }
                      >
                        Manage Drivers
                      </Button>

                      <Button
                        className="invoiceButton"
                        key="submit"
                        type="primary"
                        onClick={() =>
                          setSelectedItem({
                            id: 2,
                            url: "https://altablue.cloud/api/drivermanagers/all",
                          })
                        }
                      >
                        Manage Driver Managers
                      </Button>
                    </div>
                  )}
                </div>,
              ]
        }
      >
        <h3 style={{ textAlign: "center", margin: "6px 0" }}>Delete Company</h3>
        {!error ? (
          <>
            <p style={{ textAlign: "center", marginBottom: "4px" }}>
              Are You sure you want to delete this company ?
            </p>
            <p style={{ textAlign: "center", marginBottom: "4px" }}>
              This action will permanently delete this company and can not be
              undone.
            </p>
          </>
        ) : (
          <>
            <p style={{ textAlign: "center", marginBottom: "4px" }}>
              There are Invoices, Drivers and Driver Managers associated with
              this company. All the respective Invoices, Drivers and Driver
              Managers should be deleted before deleting the company.
            </p>
            <p style={{ textAlign: "center", marginBottom: "4px" }}>
              Note : Invoices should be marked as completed before deleting.
            </p>
          </>
        )}
      </Modal>
    </div>
  );
};

export default CompanyList;